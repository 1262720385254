import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { APICore } from '../helpers/api/apiCore';

const api = new APICore();

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    const loggedInUser = api.getLoggedInUser();
    console.log(loggedInUser);
    console.log(roles);
    return (
        <Route
            {...rest}
            render={(props) => {
                if (!loggedInUser) {
                    // Not logged in, redirect to login page
                    return (
                        <Redirect
                            to={{
                                pathname: '/account/login',
                                state: { from: props.location },
                            }}
                        />
                    );
                }
                // Check if the logged-in user has the required role
                const userRoles = api.getRolesFromToken(loggedInUser.token);
                const isAuthorized = roles && roles.some(role => userRoles.includes(role));
                if (isAuthorized) {
                    // Authorized, render the component
                    return <Component {...props} />;
                } else {
                    // Not authorized, redirect to unauthorized page or homepage
                    return <Redirect to={{ pathname: '/' }} />;
                }
            }}
        />
    );
};

export default PrivateRoute;