// @flow
import { APICore } from './apiCore';
import config from '../../config';
const api = new APICore();
const BASE_URL = config.BackEnd_API;
// account
function login(params: any): any {
    //https://localhost:7091
    // https://remaxeg.somee.com/api/AuthManagment/Login
    const baseUrl = BASE_URL+'AuthManagment/Login';
    console.log(params);
    return api.create(`${baseUrl}`, params);
}

function logout(): any {
    const baseUrl = '/logout/';
    //localStorage.clear();
    window.location.href = '/';
    return api.create(`${baseUrl}`, {});
}

function signup(params: any): any {
    const baseUrl = '/register/';
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: any): any {
    const baseUrl = '/forget-password/';
    return api.create(`${baseUrl}`, params);
}

function forgotPasswordConfirm(params: any): any {
    const baseUrl = '/password/reset/confirm/';
    return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword, forgotPasswordConfirm };
