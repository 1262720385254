import jwtDecode from 'jwt-decode';
import axios from 'axios';
import config from '../../config';

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = config.API_URL;

// intercepting to capture errors
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;

        if (error && error.response && error.response.status === 404) {
            console.log(error);
            window.location.href = '/not-found';
        } else if (error && error.response && error.response.status === 403) {
            window.location.href = '/access-denied';
        } else {
            switch (error.response.status) {
                case 401:
                    message = 'Invalid credentials';
                    break;
                case 403:
                    message = 'Access Forbidden';
                    break;
                case 404:
                    message = 'Sorry! the data you are looking for could not be found';
                    break;
                default: {
                    message =
                        error.response && error.response.data ? error.response.data['message'] : error.message || error;
                }
            }
            return Promise.reject(message);
        }
    }
);

const AUTH_SESSION_KEY = 'hyper_user';

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
    if (token) axios.defaults.headers.common['Authorization'] = 'JWT ' + token;
    else delete axios.defaults.headers.common['Authorization'];
};
 
const getUserFromSession = () => {
    const user = sessionStorage.getItem(AUTH_SESSION_KEY);
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};


class APICore {
    /**
     * Fetches data from given url
     */
    get = (url, params) => {
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, params);
        } else {
            response = axios.get(`${url}`, params);
        }
        return response;
    };
 

    getFile = (url, params) => {
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, { responseType: 'blob' });
        } else {
            response = axios.get(`${url}`, { responseType: 'blob' });
        }
        return response;
    };

    getMultiple = (urls, params) => {
        const reqs = [];
        let queryString = '';
        if (params) {
            queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
        }

        for (const url of urls) {
            reqs.push(axios.get(`${url}?${queryString}`));
        }
        return axios.all(reqs);
    };

    /**
     * post given data to url
     */
    create = async (url, data) => {
    //     let config  = {headers :{
    //         'Access-Control-Allow-Origin': '*'
    //     }
    // }
        // var result = axios.post(url, data );//, config)
        // console.log(result);
        // return result;

        return await axios.post(url, data)
        .then(response => {
            // Log the response data when the request is successful
            console.log(response);
            return response; // Return the response to the caller
        })
        .catch(error => {
            // Log any errors that occur during the request
            console.error('Error:', error);
            throw error; // Throw the error to the caller for handling
        });
    };

    createWithForm = async (url,data) => {
        
        return await axios({
            method: "post",
            url: url,
            data: data,
            headers: {'Content-Type':'multipart/form-data'},
          })
            .then(function (response) {
              //handle success
              return response;
            })
            .catch(function (response) {
              //handle error
              return response;
            });

    };

    /**
     * Updates patch data
     */
    updatePatch = (url, data) => {
        return axios.patch(url, data);
    };
    createForm =(url,data) => {
        return axios.post(url, data, {
            headers: {
            "Content-type": "multipart/form-date",
        },
        });
    };
    /**
     * Updates data
     */
    update = (url, data) => {
        return axios.put(url, data);
    };

    /**
     * Deletes data
     */
    delete = (url) => {
        return axios.delete(url);
    };

    /**
     * post given data to url with file
     */
    createWithFile = (url, data) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post(url, formData, config);
    };

    /**
     * post given data to url with file
     */
    updateWithFile = (url, data) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.patch(url, formData, config);
    };

    isUserAuthenticated = async () => {
        const user = this.getLoggedInUser();
        if (!user || (user && !user.token)) {
            return false;
        }
        
        const decoded = jwtDecode(user.token);
        const currentTime = Date.now() / 1000;

        console.log(axios.defaults.baseURL);
        let data ={"token":user.token,"refreshToken":user.refreshToken}

        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            console.log(data);
            let refreshTokenResult = {};
            const fetchData = async ()=> this.create(axios.defaults.baseURL+'AuthManagment/RefreshToken',
            data).then(res => {
                console.log(res);
                console.log('inside result');
                if(res.data.result == false)
                {
                    window.location.href = '/';
                    return false
                }
            });
            var result = await fetchData();
            //console.log(result);
            // var results =  () => axios.post('https://localhost:7090/api/AuthManagment/RefreshToken',
            // data ).then(res => {
                
            
            //     refreshTokenResult = res.data;
            //     console.log(refreshTokenResult);

            //     if(res.data.result == false)
            //     {
            //         window.location.href = '/account/login';
            //         return false;
            //     }
            
            // });
            
            // if (refreshTokenResult.result == false)
            // {
            //     return false;

            // }else{

            //     console.log(refreshTokenResult);
            //     //setAuthorization(results.data['token']);
            //     return false;

            // }
            //return false;
            return false;
        } else {
            const fetchData = async ()=> this.create('https://localhost:7090/api/AuthManagment/RefreshToken',
            data).then(res => {
                console.log(res.data);
                this.setUserInSession(res.data);
            });
            return true;
        }
    };
    
    getUserData = (token)=>{

        const result = jwtDecode(token);
        return result;
    }

    getRolesFromToken = (token) =>
    {
        const result = jwtDecode(token);
        return result['role'];
    };  

    setLoggedInUser = (session) => {
        if (session) sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
        else {
            sessionStorage.removeItem(AUTH_SESSION_KEY);
        }
    };

    /**
     * Returns the logged in user
     */
    getLoggedInUser = () => {
        return getUserFromSession();
    };

    setUserInSession = (modifiedUser) => {
        let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
        if (userInfo) {
            const { token,refreshToken, user } = JSON.parse(userInfo);
            this.setLoggedInUser({ token, refreshToken, ...user, ...modifiedUser });
        }
    };
}

/*
Check if token available in session
*/
let user = getUserFromSession();
if (user) {
    const { token } = user;
    if (token) {
        setAuthorization(token);
    }
}

export { APICore, setAuthorization,getUserFromSession };
